.menu_container {
  width: 60px;
  height: 60px;

  background-color: #E53E3E;

  display: flex;
  align-items: center;
  justify-content: center;

  /* flex: 1; */

  border-radius: 50%;
}

@media (orientation: landscape) and (min-width: 812px) and (min-height:375px)  {
  .menu_container_parent {
    width: unset !important;
  }
}

@media screen and (min-width: 320px){
  .menu_container {
    width: 20px;
    height: 20px;
  }

  .menu_container svg {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 600px){
  .menu_container {
    width: 30px;
    height: 30px;
  }

  .menu_container svg {
    font-size: 1rem;
  }
}

@media screen and (min-width: 800px){
  .menu_container {
    width: 40px;
    height: 40px;
  }

  .menu_container svg {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1080px){
  .menu_container {
    width: 60px;
    height: 60px;
  }

  .menu_container svg {
    font-size: 2rem;
  }
}